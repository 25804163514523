/* eslint-disable @typescript-eslint/naming-convention */
export const AppConstants = {
  ADD_ICON: 'add-circle-outline',
  BLE_INITIALIZED: 'ble_initialized',
  CURRENT_ROUTE: 'current_route',
  DEFAULT_VALIDATOR: 'required',
  DEVICE_NAME: 'STOP',
  EDIT_ICON: 'create-outline',
  FOREGROUND_SERVICE_ID: 1234567890,
  FOREGROUND_SERVICE_NAME: 'Default',
  GA_CONSENT: 'ga_consent',
  GEO_LOCATION: 'geo_location',
  SKIP_DISCLAIMER: 'skip_disclaimer',
  SKIP_INTRO: 'skip_intro',
  SKIP_SETUP: 'skip_setup',
  STORAGE_KEY: 'stop',
  USER_DATA: 'user_data',
  VALIDATORS_KEY: 'validators'
};
